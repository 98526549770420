<script>
import { ArrowUpIcon ,ArrowRightIcon} from "vue-feather-icons";
import Navbar from "@/components/navbar-private";
import Footer from "@/components/footer";
import Vue from 'vue'
import duppla from '@/duppla'
import moment from "moment";

/**
 * Index-developer component
 */
export default {
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    ArrowRightIcon
  },
  data() {
    return {
      uuid: this.$store.state.auth.tokenPayload.uuid,
      user: {},
      attachments: {pa: [], others:[]},

      fileNameToDelete: '',
      folderToDelete: '',
      deletingObject: false,

      loadingMedicalRecord: false,
      resultDocument: null,
      status: [
        'No Inicido',
        'Expediente recibido. El Dr. Luis Lojero Wheatley, su coordinador médico, está valorando la suficiencia de la información',
        'En consulta con el especialista que revisa tu caso',
        'Realizando informe de resultados',
        '¡Tu segunda opinión ya está lista!'
      ]
    };
  },
  computed: {
    linkedLists () {
      let list = [
        "symptoms",
        "allergies",
        "surgeries",
        "medicines",
        "direct_relatives",
        "diseases"
      ]
      return list
    },
    fields () {
      let fields = ''
      fields += '?fields%5B%5D=contact_email'
      fields += '&fields%5B%5D=contact_firstname'
      fields += '&fields%5B%5D=contact_lastname'
      fields += '&fields%5B%5D=contact_phone_country'
      fields += '&fields%5B%5D=contact_phone'
      fields += '&fields%5B%5D=user_is_patient'
      fields += '&fields%5B%5D=patient_firstname'
      fields += '&fields%5B%5D=patient_lastname'
      fields += '&fields%5B%5D=patient_country'
      fields += '&fields%5B%5D=patient_birth'
      fields += '&fields%5B%5D=patient_sex'
      fields += '&fields%5B%5D=wizard_step'
      fields += '&fields%5B%5D=form_completed_date'
      fields += '&fields%5B%5D=payment'
      fields += '&fields%5B%5D=status'
      return fields
    },
    currYear () {
      return moment().year()
    },
    currDate () {
      return moment().format('YYYY-MM-DD')
    },
    progress () {
      if (this.user.form_completed_date) {
        return 100
      }
      let steps = [
        {
          form: "init",
          submitForm: true
        },{
          form: "pa",
          submitForm: true
        },{
          form: "paadi",
          submitForm: true
        },{
          form: "appq",
          submitForm: true
        },{
          form: "agine",
          submitForm: true
        },{
          form: "apnp",
          submitForm: true
        },{
          form: "checkout",
          submitForm: true
        },{
          form: "checkout_confirmation",
          submitForm: true
        },{
          form: "end",
          submitForm: true
        }
      ]
      if (this.user) {
        // console.log('this.user.payment: ' + this.user.payment)
        if (this.user.payment) {
          steps.splice(6, 1) // checkout
        } else {
          steps.splice(7, 1) // checkout_confirmation
        }

        if (this.user.patient_sex === 'male') {
          steps.splice(4, 1) // agine
        }
      }

      // console.log('steps (looking for '+this.user.wizard_step+'): ' + JSON.stringify(steps, 2, null))

      let step = 0
      let totalSteps = steps.length - 1
      for(var i=0; i<steps.length; i++) {
        if(steps[i].form === this.user.wizard_step) {
          step = i
        }
      }
      return Math.round((step / totalSteps) * 100)
    },
  },
  methods: {
    getMedicalRecord () {
      return new Promise((resolve, reject) => {
        if(!this.loadingMedicalRecord){
          this.loadingMedicalRecord = true
          duppla({ url: 'my-medical-record' + this.fields, method: 'GET', data: null })
            .then(response => {
              if(!response.data){
                throw new Error("Expecting one record")
              }
              this.user = response.data.fields
              resolve()
            })
            .catch(error => {
              reject(error)
            })
            .finally(() => {
              this.loadingMedicalRecord = false
            })
        }
      })
    }
  },
  created () {
    // this.getMedicalRecord().then(() => {}).finally(() => {})
    this.getMedicalRecord().then((step) => {
      if (this.user.payment && this.user.wizard_step === 'checkout') {
        this.user.wizard_step = 'checkout_confirmation'
      }
    }).finally(() => {})
  },
  mounted() {
  }
};
</script>

<template>
  <div>
    <Navbar :hideMedicalRecordOpt="!(this.user.wizard_step && this.user.form_completed_date)" :hidePaymentOpt="!(this.user.wizard_step && this.user.form_completed_date)"/>

    <!-- Hero Start -->
    <section class="bg-half-170 d-table w-100 home-wrapper overflow-hidden" style="padding-top: 70px; padding-bottom: 90px;">
      <div class="container" v-if="this.user.contact_email && !this.user.form_completed_date">
        <div class="row mt-5 align-items-center">
          <div class="col-lg-12 col-md-12">
            <div class="alert" role="alert" style="text-align: justify; text-justify: inter-word !important;">
              <h5>Bienvenido a Duppla {{user.contact_firstname}}, </h5>
              <h6>
                Agradecemos la confianza que depositas en nosotros, estamos aquí para apoyarte.<br/>
                Para iniciar el proceso de segunda opinión es indispensable que llenes el expediente clínico del paciente.
                <br/><br/>

                La información que vas a requerir compone la historia clínica del paciente, incluyendo el diagnóstico principal (razón para obtener una opinión). Vale la pena tener preparados los resultados de laboratorio, imagen y patología en formato digital.
                <br/><br/>
                Comienza cuando estés listo.
              </h6>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <div class="container mt-5">
        <div class="position-relative" style="z-index: 1">
          <div class="card rounded shadow-lg bg-white p-4">
            <div class="row" id="counter">
              <div class="col-md-4">
                <div class="counter-box text-center">
                  <b-spinner v-if="loadingMedicalRecord" style="width: 3rem; height: 3rem;" variant="primary" label="Cargando..."></b-spinner>
                  <h1 class="mb-0 mt-3" v-if="!loadingMedicalRecord">
                    <span class="counter-value" data-count="99">{{progress}}</span>%
                  </h1>
                  <h5 class="counter-head mb-1">Progreso del expediente clínico</h5>
                </div>
                <!--end counter box-->
              </div>
              <!--end col-->

              <div class="col-md-8 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="counter-box text-center" v-if="progress === 0 && this.user.contact_email && !this.user.form_completed_date">
                  <router-link to="/private/medical-record-wizard" class="btn btn-success mt-3">¡Comienza Ahora!</router-link>
                  <p class="text-muted mb-0 mt-2">Llena lo que puedas ahora y regresa después. <br/>Puedes subir la información en varias sesiones y guardar parcialmente.</p>
                </div>
                <div class="counter-box text-center" v-if="progress !== 0 && this.user.wizard_step && !this.user.form_completed_date">
                  <router-link to="/private/medical-record-wizard" class="btn btn-success  mt-4">Completa tu expediente</router-link>
                </div>
                <div class="counter-box text-center" v-if="this.user.wizard_step && this.user.form_completed_date">
                  <div class="alert alert-success alert-pills mt-4" role="alert">
                     <span class="alert-content h5"> ¡Expediente completo! </span>
                  </div>
                  <p class="text-muted mb-0 mt-0">Paciente: {{user.patient_firstname}} {{user.patient_lastname}}</p>
                </div>
                <!--end counter box-->
              </div>
              <!--end col-->
            </div>
            <div class="row mt-4" v-if="this.user.wizard_step && this.user.form_completed_date">
              <div class="col-md-12 pt-2 mt-sm-0 pt-sm-0">
                <div class="alert alert-light" role="alert" style="text-align: justify; text-justify: inter-word !important;">
                  Tenemos toda la información que requerimos de tu parte para proceder con la segunda opinión, en caso de requerir algo adicional te contactaremos vía correo electrónico a <strong><i>{{user.contact_email}}</i></strong>, o bien, al teléfono <strong><i>{{user.contact_phone}}</i></strong>
                </div>
              </div>
            </div>
            <div class="row mt-2" v-if="this.user.wizard_step && this.user.form_completed_date">
                <div class="col-md-12"><hr/></div>
                <div class="col-md-3 pt-2 mt-sm-0 pt-sm-0">
                  <span class="h5">Estatus del proceso: </span>
                </div>
                <div class="col-md-9 pt-2 mt-sm-0 pt-sm-0">
                  <div class="alert alert-pills mt-md-0 mt-3" :class="user.status==4?'alert-success':'alert-warning'" role="alert"> 
                     <span class="alert-content h6"> {{status[user.status]}}</span>
                  </div>
                </div>
            </div>
            <!--end row-->
          </div>
          <!--end row-->
        </div>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Start -->
    <!--section class="section bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <div
              class="card features fea-primary rounded p-4 bg-white position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <i class="uil uil-newspaper"></i>
              </span>
              <div class="card-body p-0 content">
                <h5>Appointment</h5>
                <p class="para text-muted mb-0">
                  It is a long established fact that a reader layout.
                </p>

                <a
                  href="javascript:void(0)"
                  class="btn btn-pills btn-soft-success mt-4"
                  data-toggle="modal"
                  data-target="#productview"
                  >Request</a
                >
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div
              class="card features fea-primary rounded p-4 bg-white position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <i class="uil uil-clock"></i>
              </span>
              <div class="card-body p-0 content">
                <h5>Opening Hour</h5>

                <h6 class="mb-0">Monday - Friday</h6>
                <p class="para text-muted">7AM to 11 PM</p>

                <h6 class="mb-0">Address:</h6>
                <p class="para text-muted mb-0">C/54 Northwest Freeway</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div
              class="card features fea-primary rounded p-4 bg-white position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <i class="uil uil-user-md"></i>
              </span>
              <div class="card-body p-0 content">
                <h5>Find Doctors</h5>
                <p class="para text-muted mb-0">
                  It is a long established fact that a reader layout.
                </p>

                <a
                  href="javascript:void(0)"
                  class="btn btn-pills btn-soft-success mt-4"
                  >Doctors</a
                >
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div
              class="card features fea-primary rounded p-4 bg-white position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <i class="uil uil-map-marker"></i>
              </span>
              <div class="card-body p-0 content">
                <h5>Find Locations</h5>
                <p class="para text-muted mb-0">
                  It is a long established fact that a reader layout.
                </p>

                <a
                  href="javascript:void(0)"
                  class="btn btn-pills btn-soft-success mt-4"
                  >Locations</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section-->
    <!--end section-->
    <!-- End -->
    <Footer />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>

</style>
